import { Address } from '@neo1/client/lib/entities/address/types';
import {
  ConfirmationLevel,
  ValidationResult,
} from '@neo1/core/utils/addressValidation';

export const UK_COUNTRY_CODE = 'GB';

export const US_COUNTRY_CODE = 'US';

const findAddressComponent =
  (...searchedTypes: string[]) =>
  (addressComponent: google.maps.GeocoderAddressComponent): boolean =>
    searchedTypes.every((type) => addressComponent.types.includes(type));

export const getSuspeciousKeys = (
  addressValidation: ValidationResult['address'],
): string[] => {
  const { unconfirmedComponentTypes } = addressValidation;
  return (
    unconfirmedComponentTypes?.filter((type) => {
      const confirmationLevel = addressValidation.addressComponents.find(
        ({ componentType }) => componentType === type,
      )?.confirmationLevel;
      return (
        confirmationLevel !== ConfirmationLevel.CONFIRMED &&
        confirmationLevel !== ConfirmationLevel.UNCONFIRMED_BUT_PLAUSIBLE
      );
    }) || []
  );
};

export const getCountry = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
): string | undefined =>
  addressComponents.find(findAddressComponent('country'))?.short_name;

export const getPostalCode = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
): string | undefined => {
  const postalCode = addressComponents.find(
    findAddressComponent('postal_code'),
  )?.long_name;
  const postalCodeSuffix = addressComponents.find(
    findAddressComponent('postal_code_suffix'),
  )?.long_name;
  // !important take the postal code suffix only for US addresses
  return getCountry(addressComponents) === US_COUNTRY_CODE && postalCodeSuffix
    ? `${postalCode}${postalCodeSuffix ? `-${postalCodeSuffix}` : ''}`
    : postalCode;
};

export const getCity = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
): string | undefined =>
  (getCountry(addressComponents) === UK_COUNTRY_CODE
    ? addressComponents.find(findAddressComponent('postal_town'))
    : addressComponents.find(findAddressComponent('locality', 'political')) ||
      addressComponents.find(findAddressComponent('sublocality', 'political'))
  )?.long_name;

export const getState = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
):
  | google.maps.GeocoderAddressComponent['short_name']
  | google.maps.GeocoderAddressComponent['long_name']
  | undefined => {
  const found = addressComponents.find(
    getCountry(addressComponents) === UK_COUNTRY_CODE
      ? findAddressComponent('administrative_area_level_2', 'political')
      : findAddressComponent('administrative_area_level_1', 'political'),
  );
  if (found) {
    return found[
      getCountry(addressComponents) === US_COUNTRY_CODE
        ? 'short_name'
        : 'long_name'
    ];
  }
  return undefined;
};

export const getStreetNumber = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
): string | undefined =>
  addressComponents.find(findAddressComponent('street_number'))?.long_name;

export const getStreetName = (
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
): string | undefined =>
  addressComponents.find(findAddressComponent('route'))?.long_name;

export type SelectedAddress = Omit<
  Address,
  'name' | 'id' | 'entityName' | 'userId' | 'streetLine2'
>;

export const getAddressFromPlace = (
  placeDetails: google.maps.places.PlaceResult,
): SelectedAddress => {
  const street = `${getStreetNumber(placeDetails.address_components) || ''} ${
    getStreetName(placeDetails.address_components) || ''
  }`;
  return {
    zipCode: getPostalCode(placeDetails.address_components),
    city: getCity(placeDetails.address_components) || '',
    state: getState(placeDetails.address_components) || '',
    countryCode: getCountry(placeDetails.address_components) || '',
    streetLine1: street,
  };
};
