import { Address } from '@neo1/client/lib/entities/address/types';
import {
  Country,
  CountryState,
} from '@neo1/client/lib/entities/referentialData/types';

const US_CODE = 'US';
const PUERTO_RICO_CODE = 'PR';

// ! Note: these are not exhaustive types, we only determine what we need
// for reference: https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#postaladdress
type PostalAddress = {
  //two chars country code
  regionCode: Country['code'];
  locality: Address['city'];
  // state/region (political division), two chars code for the US.
  administrativeArea: CountryState['code'] | string;
  postalCode: Address['zipCode'];
  addressLines: string[];
};

// for reference: https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#uspsaddress
type UspsStandardizedAddress = {
  firstAddressLine: string;
  secondAddressLine: string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeExtension: string;
};

export enum ConfirmationLevel {
  CONFIRMATION_LEVEL_UNSPECIFIED = 'CONFIRMATION_LEVEL_UNSPECIFIED',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED_BUT_PLAUSIBLE = 'UNCONFIRMED_BUT_PLAUSIBLE',
  UNCONFIRMED_AND_SUSPICIOUS = 'UNCONFIRMED_AND_SUSPICIOUS',
}

type AddressComponent = {
  componentName: {
    text: string;
    languageCode: string;
  };
  componentType: string;
  confirmationLevel: ConfirmationLevel;
  inferred: boolean;
  spellCorrected: boolean;
  replaced: boolean;
  unexpected: boolean;
};

// for reference: https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#postaladdress
export type ValidationResult = {
  verdict: {
    inputGranularity: string;
    validationGranularity: string;
    geocodeGranularity: string;
    addressComplete: boolean;
    hasInferredComponents?: boolean;
    hasUnconfirmedComponents?: boolean;
    hasReplacedComponents?: boolean;
  };
  address: {
    formattedAddress: string;
    postalAddress: PostalAddress;
    unconfirmedComponentTypes?: string[];
    missingComponentTypes?: string[];
    addressComponents: AddressComponent[];
  };
  uspsData?: {
    dpvConfirmation?: 'Y' | 'D' | 'S' | 'N';
    standardizedAddress: UspsStandardizedAddress;
  };
};

export type AddrFields =
  | 'city'
  | 'countryCode'
  | 'state'
  | 'streetLine1'
  | 'streetLine2'
  | 'zipCode';

export type Addr = Required<Pick<Address, AddrFields>>;

const toPostalAddress = ({
  countryCode,
  city,
  state,
  zipCode,
  streetLine1,
  streetLine2,
}: Addr) => ({
  regionCode: countryCode,
  locality: city,
  administrativeArea: state,
  postalCode: zipCode,
  addressLines: streetLine2 ? [streetLine1, streetLine2] : [streetLine1],
});

const escapeQuotes = (obj: unknown) => {
  if (typeof obj === 'string') {
    return obj.replace(/'/g, "\\'");
  }
  if (Array.isArray(obj)) {
    return obj.map(escapeQuotes);
  }
  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((newObj, curr) => {
      const [key, value] = curr;
      newObj[key] = escapeQuotes(value);
      return newObj;
    }, {});
  }
  return obj;
};

export const unconfirmedcomponentsToAddrKeys = (
  unconfirmedKeys: string[],
): string[] => {
  const addrKeys = [];
  if (!unconfirmedKeys) {
    return addrKeys;
  }
  if (
    unconfirmedKeys.includes('street_number') ||
    unconfirmedKeys.includes('route')
  ) {
    addrKeys.push('streetLine1');
  }
  if (unconfirmedKeys.includes('subpremise')) {
    addrKeys.push('streetLine2');
  }
  if (unconfirmedKeys.includes('countryCode')) {
    addrKeys.push('countryCode');
  }
  if (
    unconfirmedKeys.includes('postal_code') ||
    unconfirmedKeys.includes('postal_code_suffix')
  ) {
    addrKeys.push('zipCode');
  }
  if (unconfirmedKeys.includes('administrative_area_level_1')) {
    addrKeys.push('state');
  }
  if (
    unconfirmedKeys.includes('locality') ||
    unconfirmedKeys.includes('sublocality') ||
    unconfirmedKeys.includes('postal_town')
  ) {
    addrKeys.push('city');
  }
  return addrKeys;
};

export const toAddr = ({
  addressComponents,
  postalAddress: { regionCode },
}: ValidationResult['address']) => {
  const countryCode = regionCode;

  let streetNumber = '';
  let route = '';
  let streetLine2 = '';
  let city = '';
  let state = '';
  let postalCode = '';
  let postalCodeSuffix = '';

  addressComponents.forEach(({ componentType, componentName }) => {
    const text = componentName?.text || '';
    if (componentType === 'street_number') {
      streetNumber = text;
    }
    if (componentType === 'route') {
      route = text;
    }
    if (componentType === 'subpremise') {
      streetLine2 = text;
    }
    if (
      componentType === 'postal_town' ||
      componentType === 'locality' ||
      componentType === 'sublocality'
    ) {
      city = text;
    }
    if (componentType === 'postal_code') {
      postalCode = text;
    }
    if (componentType === 'postal_code_suffix') {
      postalCodeSuffix = text;
    }
    if (
      componentType === 'administrative_area_level_1' &&
      countryCode !== 'GB'
    ) {
      state = text;
    }
    if (
      componentType === 'administrative_area_level_2' &&
      countryCode === 'GB'
    ) {
      state = text;
    }
  });
  return {
    city,
    countryCode,
    state,
    zipCode: `${postalCode}${postalCodeSuffix ? `-${postalCodeSuffix}` : ''}`,
    streetLine1: `${streetNumber} ${route}`,
    streetLine2,
  };
};

export const validateAddressApi = async (
  apiKey: string,
  a: Addr,
): Promise<ValidationResult> => {
  const address = toPostalAddress(a);
  const response = await fetch(
    `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`,
    {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(
        escapeQuotes({
          address,
          // reference: https://developers.google.com/maps/documentation/address-validation/requests-validate-address#enable-cass
          enableUspsCass: [US_CODE, PUERTO_RICO_CODE].includes(
            address.regionCode,
          ),
        }),
      ),
    },
  );
  //reference: https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#ValidationResult
  return (await response.json()).result as ValidationResult;
};
