import { useContext } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import isFunction from 'lodash/isFunction';
import { Location } from 'history';
import {
  withRouter,
  RouteComponentProps,
  matchPath,
  useParams,
} from 'react-router-dom';
import { TabsContext, AppRoute } from 'config/routes/utils';
import Tabs from 'components/layout/Tabs';
import { isMessageDescriptor } from 'utils/i18n';

interface Props extends RouteComponentProps {}

type TabsProps = {
  history: Props['history'];
  location: Props['location'];
  routes: AppRoute[];
};
const CustomTabs = ({ history, location, routes }: TabsProps) => {
  const intl = useIntl();
  const params = useParams();

  const sortedRoutes = sortBy(routes, ['routeOrder']);

  function matchesLocation(value: Location) {
    return (route: AppRoute) => !isNil(matchPath(value.pathname, route));
  }

  function mapRouteToTab(route: AppRoute) {
    function renderTitle(title: any) {
      switch (typeof title) {
        case 'function':
          return renderTitle(title());

        case 'object':
          if (isMessageDescriptor(title)) {
            return intl.formatMessage(title);
          }
          return title;

        default:
          return title;
      }
    }

    const onClickTab = () => {
      if (isFunction(route.generatePath)) {
        history.push(route.generatePath(params));
      } else if (route.defaultPath) {
        history.push(route.defaultPath);
      } else if (route.path) {
        history.push(route.path);
      }
    };

    const title = renderTitle(route.title);
    const ariaLabel = renderTitle(title?.props?.title);

    return {
      exact: route.exact,
      title,
      path: route.path,
      onClick: onClickTab,
      content: undefined,
      showElement: !route?.hideTab,
      ariaLabel,
    };
  }
  const items = sortedRoutes.map(mapRouteToTab);
  const initialTab = Math.max(
    sortedRoutes.findIndex(matchesLocation(location)),
    0,
  );
  return <Tabs initialTab={initialTab} type="route" elements={items} />;
};

function TabRoutes({ history, location }: Props) {
  const routes = useContext(TabsContext);
  // the TabsContext provides only an array of "routes",
  // so it doesn't make much sense to use destructuring assignment to get its length
  // eslint-disable-next-line react/destructuring-assignment
  const showTabs = routes.filter((r) => !r.hideTab).length > 1;

  return showTabs ? (
    <CustomTabs history={history} location={location} routes={routes} />
  ) : null;
}

export default withRouter(TabRoutes);
