export const PURCHASE_ENTITY_NAME = 'Purchase';

export const BUDGET_ENTITY_NAME = 'Budget';

export const SUPPLY_ENTITY_NAME = 'Supply';

export enum RequestType {
  Virtual = 'virtual_budget',
  Standard = 'standard_budget',
}

export enum PurchaseStatusCode {
  InProgress = 'in_progress',
  WaitingforApproval = 'waiting_for_approval',
  Approved = 'approved',
  Rejected = 'rejected',
  Frozen = 'frozen',
  Canceled = 'canceled',
}

// Tx reject sources
export const REJECT_SOURCE_USER = 'user';
export const REJECT_SOURCE_SYSTEM = 'system';

// Tx reject reasons
export const INVALID_RECEIPT = 'invalid_receipt';
export const INCORRECT_FINANCIAL_CODING = 'incorrect_financial_coding';
export const OUT_OF_POLICY = 'out_of_policy';
export const POLICY_CHANGED = 'policy_changed';
export const SUPERVISOR_NOT_ACTIVE = 'supervisor_not_active';

const INVALID_RECEIPT_LABEL = 'Invalid receipt';
const INCORRECT_FINANCIAL_CODING_LABEL = 'Incorrect financial coding';
const OUT_OF_POLICY_LABEL = 'Out of policy';
const POLICY_CHANGED_LABEL = 'Accounting policy changed';
const SUPERVISOR_NOT_ACTIVE_LABEL = 'Supervisor has been disabled';

export const PURCHASE_REJECT_REASONS = {
  [INVALID_RECEIPT]: INVALID_RECEIPT_LABEL,
  [INCORRECT_FINANCIAL_CODING]: INCORRECT_FINANCIAL_CODING_LABEL,
  [OUT_OF_POLICY]: OUT_OF_POLICY_LABEL,
  [POLICY_CHANGED]: POLICY_CHANGED_LABEL,
  [SUPERVISOR_NOT_ACTIVE]: SUPERVISOR_NOT_ACTIVE_LABEL,
};

export enum RejectedBySource {
  user = 'user',
  system = 'system',
}

export const VIRTUAL_BUDGET_CURRENCY = 'USD';

export enum RejectReasons {
  'out_of_policy',
  'incorrect_financial_coding',
}

export enum RejectChangesReasons {
  'out_of_policy',
  'other',
}

export enum AfterBudgetCloseTxAction {
  Unlinked = 'unlinked',
  Rejected = 'rejected',
}

export enum VirtualBudgetHistoryEvent {
  Created = 'created',
  Updated = 'updated',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  ChangesRequested = 'changes_requested',
  ChangesApproved = 'changes_approved',
  ChangesRejected = 'changes_rejected',
  Frozen = 'frozen',
  Unfrozen = 'unfrozen',
  Canceled = 'canceled',
}

export enum RejectSource {
  User = 'user',
  System = 'system',
}

export const VIRTUAL_BUDGET_HISTORY_ENTITY_NAME = 'BudgetHistory';

export enum SummaryType {
  TransactionSummary = 'transaction_summary',
  ObfuscatedSummary = 'obfuscated_summary',
}
