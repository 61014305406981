import { sortAlphaNumeric } from '@neo1/core/utils/collections';
import { SortFunction } from './types';

const defaultSortFunction: SortFunction = (list, sort, getSortValue) => {
  if (!sort?.prop) return list;

  return list.sort(sortAlphaNumeric(sort, getSortValue));
};

export default defaultSortFunction;
