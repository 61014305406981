import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useAsyncState from 'hooks/asyncState';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { UUID } from '@neo1/client';
import {
  selectActingCompany,
  selectCompanyUsers,
} from 'modules/Authentification/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUsersVendors,
  getVendors,
} from 'redux/accountingIntegrations/thunks';
import {
  selectUsersVendors,
  selectVendors,
} from 'redux/accountingIntegrations/selector';
import {
  CashAccountMappingPreferences,
  CompanyExtractService,
} from '@neo1/client/lib/entities/company/constants';
import { UserVendor } from '@neo1/client/lib/entities/company/types';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import DataTable from 'components/elements/DataTable';
import FormField from 'components/elements/form/Field';
import SelectBox from 'components/elements/form/fields/SelectBox';
import RadioGroup from 'components/elements/form/fields/RadioGroup';
import { FormikErrors, useFormikContext } from 'formik';
import styles from './CashTxsConfiguration.module.css';
import messages from './messages';

export type OOPValues = {
  vendorId: string;
  mappingPreferences: CashAccountMappingPreferences;
  mappings: Record<UUID, UserVendor>;
};
export type Props = {
  failedRecords: UUID[];
};

export const INITIAL_VALUES = {
  mappings: {},
  vendorId: null,
  mappingPreferences: CashAccountMappingPreferences.SingleVendor,
};

export const validationCashConf = (
  values: OOPValues,
): FormikErrors<OOPValues> => {
  let errors: FormikErrors<OOPValues> = {};

  const { mappingPreferences, vendorId, mappings: indexedMappings } = values;

  const dictionnary: { [vendorId: string]: UserVendor } = {};

  const dupIndexes: number[] = [];

  const mappingKeys = Object.keys(indexedMappings);

  if (!values.mappingPreferences) {
    errors.mappingPreferences = 'This field is required';
  }

  if (mappingPreferences === CashAccountMappingPreferences.UserMapping) {
    const mappings = Object.values(indexedMappings);

    mappings.forEach((element, index) => {
      if (element.vendorId && dictionnary[element.vendorId]) {
        dupIndexes.push(index);
      } else dictionnary[element.vendorId] = element;
    });
  }

  if (
    mappingPreferences === CashAccountMappingPreferences.SingleVendor &&
    !vendorId
  ) {
    errors.vendorId = 'Vendor is required';
  }

  dupIndexes.forEach((index) => {
    errors = {
      ...errors,
      ['mappings' as keyof OOPValues]: {
        ...errors.mappings,
        [mappingKeys[index]]: 'This vendor is already used',
      },
    };
  });
  return errors;
};

const CashTxsConfContent = ({ failedRecords }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isEmptyFailedRecords = isEmpty(failedRecords);
  const company = useSelector(selectActingCompany);
  const vendors = useSelector(selectVendors(CompanyExtractService.QuickBooks));

  const usersVendors = useSelector(
    selectUsersVendors(CompanyExtractService.QuickBooks),
  );

  const {
    resetForm,
    values: { mappingPreferences, vendorId },
    setFieldValue,
  } = useFormikContext<OOPValues>();

  const isUserMappingActive =
    mappingPreferences === CashAccountMappingPreferences.UserMapping;

  const isSingleVendorActive =
    mappingPreferences === CashAccountMappingPreferences.SingleVendor;

  const onLoad = useCallback(async () => {
    try {
      await dispatch(getVendors(company.id));
      await dispatch(getUsersVendors(company.id));
    } catch (_) {
      dispatch(notifyError(intl.formatMessage(messages.loadingError)));
    }
  }, [company.id, dispatch, intl]);

  const users = useSelector(selectCompanyUsers);

  const { execute, isLoading } = useAsyncState(onLoad);

  useEffect(() => {
    execute();
  }, [execute]);

  const concernedUsers = useMemo(
    () =>
      !isEmptyFailedRecords
        ? users.filter((u) => failedRecords.includes(u.id))
        : users,
    [failedRecords, isEmptyFailedRecords, users],
  );

  // after submitting, if api return failed recored, we need to reinitialize the form values with only concerned users
  useEffect(() => {
    resetForm({
      values: {
        mappings: concernedUsers.reduce((acc, u) => {
          const userVendor = usersVendors.find(({ userId }) => userId === u.id);
          acc[u.id] = {
            userId: u.id,
            vendorId:
              userVendor?.vendorId ??
              vendors.find(
                (v) =>
                  v.displayName
                    .trim()
                    .replaceAll(/\s{2,}/g, ' ')
                    .toLocaleLowerCase() ===
                  u.displayName
                    .trim()
                    .replaceAll(/\s{2,}/g, ' ')
                    .toLocaleLowerCase(),
              )?.id,
          };
          return acc;
        }, {}),
        vendorId,
        mappingPreferences: failedRecords.length
          ? CashAccountMappingPreferences.UserMapping
          : mappingPreferences,
      },
    });
  }, [
    resetForm,
    concernedUsers,
    vendorId,
    failedRecords,
    mappingPreferences,
    users,
    vendors,
    usersVendors,
  ]);

  const vendorsList = vendors.map((v) => ({
    label: v.displayName,
    value: v.id,
  }));

  const columns = [
    { prop: 'name', title: 'Name' },
    {
      prop: 'screeningStatus',
      title: 'Status',
      render: (_, u: { userId: UUID; name: string }) => {
        const options = vendorsList
          .concat({
            label: 'Create new vendor',
            value: undefined,
          })
          .map((v) => ({
            label: v.label,
            value: { userId: u.userId, vendorId: v.value },
          }));

        return (
          <FormField
            title="Select a vendor"
            required
            name={`mappings[${u.userId}]`}
          >
            <SelectBox
              className={styles.select}
              options={options}
              defaultValue={{
                label: 'Create new vendor',
                value: undefined,
              }}
            />
          </FormField>
        );
      },
    },
  ];

  const rows = concernedUsers.map((u) => ({
    userId: u.id,
    name: u.displayName,
  }));

  return (
    <StepContent submitTitle="Continue" isLoading={isLoading}>
      <div className={styles.container}>
        {isEmptyFailedRecords && (
          <RadioGroup
            onChange={(v) => setFieldValue('mappingPreferences', v)}
            value={mappingPreferences}
            options={[
              {
                label: intl.formatMessage(messages.singleVendor),
                value: CashAccountMappingPreferences.SingleVendor,
                testId: 'singlevendor',
              },
              {
                label: intl.formatMessage(messages.userMapping),
                value: CashAccountMappingPreferences.UserMapping,
                testId: 'usermapping',
              },
            ]}
            horizontal
          />
        )}
        {!isEmptyFailedRecords && (
          <p className={classNames(styles.failedRecordMessage, 'textM')}>
            {intl.formatMessage(messages.failedRecordsMessage)}
          </p>
        )}
        {isUserMappingActive && (
          <DataTable
            name="paymentsInstruments"
            columns={columns}
            rows={rows}
            exportActions={null}
            rowKeyProp="userId"
          />
        )}
        {isSingleVendorActive && isEmptyFailedRecords && (
          <FormField
            title={intl.formatMessage(messages.vendor)}
            required
            name="vendorId"
          >
            <SelectBox options={vendorsList} />
          </FormField>
        )}
      </div>
    </StepContent>
  );
};

export default CashTxsConfContent;
